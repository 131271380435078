<template>
    <form id="order-payment" class="order-form-content" action="">
        <div class="form-group">
            <label for="tx_id" v-html="trans('order.tx_id-label')"></label>
            <input type="text" class="form-control" id="tx_id" aria-describedby="txIdExplanation"
                   v-model="tx_id" name="tx_id"
                   v-validate="{ required: true, regex:/^[a-zA-Z0-9]{40,128}$/ }"
                   :placeholder="trans('common.tx_id_long')"
                   autocomplete="off"
                   v-bind:class="{ 'is-invalid':errors.has('tx_id')}"
            >
            <div class="invalid-feedback" v-if="errors.has('tx_id')" v-html="trans('common.validation-required')"></div>
            <!--<small id="txIdExplanation" class="form-text text-muted">We'll never share your email with anyone else.</small>-->
        </div>
        <ul class="list-unstyled mt-3">
            <li>
                <button type="button" class="btn btn-link m-0 p-0" data-toggle="modal" data-target="#txIdExplanation">
                    <i class="fas fa-question-circle"></i> {{ trans('order.where-to-find-this-value') }}
                </button>
            </li>
        </ul>
        <!-- Modal -->
        <div class="modal fade" id="txIdExplanation" tabindex="-1" role="dialog" aria-labelledby="txIdExplanation"
             aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header modal-header-gray">
                        <h5 class="modal-title" id="walletExplanationLabel">
                            <i class="fas fa-question-circle"></i> {{ trans('order.where-to-find-this-value') }}
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body" v-html="txIdExplanation"></div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-info" data-dismiss="modal">{{
                                trans('common.close')
                            }}
                        </button>
                        <!--<button type="button" class="btn btn-primary">Save changes</button>-->
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<script>

export default {
    name: 'order-tx-id',
    props: ['currency', 'defaultTxId'],
    inject: ['trans'],
    components: {},
    data() {
        return {
            tx_id: null,
        }
    },
    created() {
        this.tx_id = this.defaultTxId
    },
    computed: {
        txIdExplanation() {
            return this.trans('order.tx_id_explanation')
                .replace(":crypto", this.currency.name)
                .replace(":crypto", this.currency.name)
        }
    },
    methods: {
        validate() {
            let isValid = this.$validator.validateAll()
            this.$emit('on-validate', this.$data, isValid)
            return isValid
        }
    },
    watch: {
        tx_id(value) {
            this.$root.$emit('update:tx_id', value)
        }
    }
}
</script>
