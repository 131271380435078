<template>
    <div class="kyc-veriff">

        <ul class="list-unstyled mt-3 text-center">
            <li>
                <button type="button" class="btn btn-link m-0 p-0" data-toggle="modal" data-target="#kycExplanation">
                    <i class="fas fa-question-circle"></i> {{ trans('common.why-we-need-these-data') }}
                </button>
            </li>
        </ul>
        <!-- FAQ Explanation Modal -->
        <div class="modal fade" id="kycExplanation" tabindex="-1" role="dialog" aria-labelledby="kycExplanation"
             aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header modal-header-gray">
                        <h5 class="modal-title" id="kycExplanationLabel">
                            <i class="fas fa-question-circle"></i> {{ trans('common.why-we-need-these-data') }}
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body" v-html="verificationExplanation"></div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-info" data-dismiss="modal">{{
                                trans('common.close')
                            }}
                        </button>
                        <!--<button type="button" class="btn btn-primary">Save changes</button>-->
                    </div>
                </div>
            </div>
        </div>

        <div v-if="validated" class="text-center">
            ✅ {{ trans('common.veriff-sucessfully-submitted') }}
        </div>
        <div v-if="!validated">
            <div id='veriff-root'></div>
        </div>

        <div v-if="false">
            <strong>Debug:</strong>
            <button @click="mockVeriffResponse">mock veriff finish</button>
            <pre>{{ {veriff, verificationResponse, veriffSessionUrl} }}</pre>
            <pre v-if="false">{{ {initKyc, order} }}</pre>
        </div>
    </div>
</template>

<script>
import {Veriff} from '@veriff/js-sdk';
import {createVeriffFrame, MESSAGES} from '@veriff/incontext-sdk';

export default {
    name: "kyc-veriff",
    props: ['veriff', 'order', 'initKyc', 'kycThreshold'],
    inject: ['trans'],
    data() {
        return {
            verificationResponse: {},
            validated: false,
        }
    },
    mounted() {
        this.initVeriff()
    },
    computed: {
        firstName() {
            return this.initKyc && this.initKyc.FIRST_NAME && String(this.initKyc.FIRST_NAME)
        },
        lastName() {
            return this.initKyc && this.initKyc.LAST_NAME && String(this.initKyc.LAST_NAME)
        },
        userId() {
            return (this.order && this.order.user_id && String(this.order.user_id)) || undefined
        },
        verificationExplanation() {
            return this.trans('order.kyc-explanation-html')
                .replace(":threshold", this.kycThreshold || 0)
                .replace(":link", this.termsLink)
        },
        termsLink() {
            let link = app.translator.locale + '/terms-and-conditions'
            let title = this.trans('common.terms-and-conditions')
            return `<a href="/${link}" target="_blank">${title}</a>`;
        },
        veriffSessionUrl() {
            return this.order.user.veriff_url;
        },
    },
    methods: {
        initVeriff() {
            const veriff = Veriff({
                host: this.veriff.url,
                apiKey: this.veriff.apiKey,
                parentId: 'veriff-root',
                onSession: (err, response) => {
                    if (err) {
                        laravel.alert('Error during Veriff session creation');
                        console.error('Error during Veriff session creation: ', err);
                        return;
                    }
                    this.verificationResponse = response.verification
                    const url = this.veriffSessionUrl || response.verification.url
                    this.updateUserVeriffStatus(url)
                    createVeriffFrame({
                        url,
                        lang: 'cs',
                        onEvent: (msg) => {
                            switch (msg) {
                                case MESSAGES.STARTED:
                                    this.started();
                                    break;
                                case MESSAGES.FINISHED:
                                    this.updateUserVeriffStatus(url, 'submitted');
                                    this.finished();
                                    break;
                                case MESSAGES.CANCELED:
                                    break;
                                case MESSAGES.RELOAD_REQUEST:
                                    window.location.reload();
                                    break;
                            }
                        }
                    });
                },
            });
            veriff.setParams({
                person: {
                    givenName: this.firstName,
                    lastName: this.lastName,
                    // idNumber: this.userId, // this should be National identification number (not gocoinId)
                },
                vendorData: this.order.user_id ? String(this.order.user_id) : undefined,
            });
            veriff.mount({
                formLabel: {
                    givenName: this.trans('common.kyc-firstname'),
                    lastName: this.trans('common.kyc-lastname'),
                    vendorData: 'UserId', // not visible
                },
                submitBtnText: this.trans('common.veriff-start'),
            });

        },
        started() {
            this.$emit('started')
        },
        finished() {
            this.validated = true
            this.$emit('finished', this.verificationResponse)
        },
        validate() {
            this.$emit('on-validate', this.$data, this.validated)
            return this.validated
        },
        updateUserVeriffStatus(url, status) {
            let data = {
                veriff_url: url,
                veriff_status: status,
            }
            laravel.ajax.send({
                method: 'POST',
                url: this.veriff.userStatusUrl,
                data: data,
            })
        },
        mockVeriffResponse() {
            this.verificationResponse = {
                "id": "8639f846-eabe-45be-a645-578f7232bb4a",
                "url": "https://alchemy.veriff.com/v/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZXNzaW9uX2lkIjoiODYzOWY4NDYtZWFiZS00NWJlLWE2NDUtNTc4ZjcyMzJiYjRhIiwiaWlkIjoiNjdmNjMwMjQtM2ZjZS00OGYyLTlkNTEtMzAwYzY3MWNhY2NhIiwiaWF0IjoxNjcwNzYxNDE5fQ.Q5VQGDlUwbysWX_ETjvuIQcBdARtSBE8CZ_AlyKaxh4",
                "vendorData": "72",
                "host": "https://alchemy.veriff.com",
                "status": "created",
                "sessionToken": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZXNzaW9uX2lkIjoiODYzOWY4NDYtZWFiZS00NWJlLWE2NDUtNTc4ZjcyMzJiYjRhIiwiaWlkIjoiNjdmNjMwMjQtM2ZjZS00OGYyLTlkNTEtMzAwYzY3MWNhY2NhIiwiaWF0IjoxNjcwNzYxNDE5fQ.Q5VQGDlUwbysWX_ETjvuIQcBdARtSBE8CZ_AlyKaxh4"
            }
            this.finished();
        }
    }
}
</script>

<style lang="scss">
.kyc-veriff {

}

#veriff-root {
    max-width: 400px;
    padding: 1rem;
    margin-top: 1rem;
    margin-left: auto;
    margin-right: auto;
}

.veriff-submit {
    background-color: #007bff;
    cursor: pointer;

    &:hover {
        background-color: #0069d9;
    }
}
</style>
