import innerHeight from './../vendor/iosInnerHeight'

export default {
    getHeight() {
        return innerHeight()
    },
    getWidth() {
        return $(window).width()
    },
    setCanvas(canvas) {
        let width = this.getWidth();
        let height = this.getHeight();
        canvas.width = width * this.getRatio()
        canvas.height = height * this.getRatio()
        canvas.style.width = width + "px"
        canvas.style.height = height + "px"
        // console.info('setCanvas', width,height,this.getRatio() );
    },
    setContext(ctx) {
        ctx.scale(this.getRatio(), this.getRatio())
    },
    setCanvasWithContext(canvas, ctx) {
        this.setCanvas(canvas)
        this.setContext(ctx)
    },
    getPixelRatio() {
        return window.devicePixelRatio
    },
    customRatio: null,
    getRatio() {
        return this.customRatio || this.getPixelRatio()
    },
    startResize(canvas) {
        canvas.style.width = '0px'
        canvas.style.height = '0px'
    },
}
