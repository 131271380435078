import dimensions from './dimensions'
import {TweenLite} from 'gsap'
import debounce from 'debounce'

/**
 * Canvas Experiment
 * Based on https://tympanus.net/Development/AnimatedHeaderBackgrounds/index.html
 * Deps: GreenSocks TweenLite
 */

/**
 * Constructor
 */
function Animate(canvas, options) {
    this.canvas = canvas;
    this.requestedFrame = null
    this.ctx = this.canvas.getContext('2d');
    this.options = defaults(options || {}, this.options);
    this.init();
}

/**
 * Default options
 */
Animate.prototype.options = {
    density: 10, // Affects how many points are created
    speed: 100, // Time in seconds to shift points
    sync: false, // Should points move in sync
    distance: 100, // Distance to move points
    lineColor: '100, 255, 255',
    circleColor: '185, 255, 255',
    radius: 5,
    lineWidth: 1,
    lines: 3,  // Number of closest lines to draw
    updateClosest: false, // Update closet points each loop
    updateClosestInterval: 30000,
    mouse: true, // Link to mouse or random

};


/**
 * Setup everything
 */
Animate.prototype.init = function () {
    // dimensions.customRatio = this.options.mouse ? dimensions.getPixelRatio() : 1
    this.width = dimensions.getWidth()
    this.height = dimensions.getHeight()
    this.target = {
        position: {
            x: this.width / 2,
            y: this.height / 2
        }
    };

    // Setup canvas
    dimensions.setCanvasWithContext(this.canvas, this.ctx)

    window.addEventListener('resize', this.resize.bind(this));

    if (this.options.mouse === true) {
        window.addEventListener('mousemove', this.mousemove.bind(this));
    }

    this.initGeometry();

    this.animate(); // Start the loop

    // this.shiftPoints(); // Start the tween loop

    if (this.options.mouse === false) {
        this.moveTarget(); // Start the random target loop
    }

};

Animate.prototype.initGeometry = function () {

    let density = Math.floor(this.options.density * (this.width + this.height) / 1000)

    this.points = [];
    for (var x = 0; x < this.width; x = x + this.width / density) {
        for (var y = 0; y < this.height; y = y + this.height / density) {
            var point = new Point({
                x: x + Math.random() * this.width / this.options.density,
                y: y + Math.random() * this.height / this.options.density
            }, this.ctx, this.points.length + 1, this.options);
            this.points.push(point);
        }
    }

    // Setup Circles
    for (var i in this.points) {
        this.points[i].circle = new Circle(this.points[i], this.ctx, this.options);
    }

    this.findClosest(); // Points

    this.shiftPoints();

};

/*
 * Cycles through each Point and finds its neighbors
 */
Animate.prototype.findClosest = function () {
    let options = this.options
    options.updateClosest = false;
    setTimeout(function () {
        options.updateClosest = true;
    }, options.updateClosestInterval);

    for (var i = 0; i < this.points.length; i++) {
        // Save the point
        var point = this.points[i];
        // Reset
        point.closest = [];
        // Cycle through the others
        for (var j = 0; j < this.points.length; j++) {
            // Point to test
            var testPoint = this.points[j];
            if (point.id !== testPoint.id) {
                var placed = false, k;
                for (k = 0; k < this.options.lines; k++) {
                    if (!placed) {
                        if (typeof point.closest[k] === 'undefined') {
                            point.closest[k] = testPoint;
                            placed = true;
                        }
                    }
                }

                for (k = 0; k < this.options.lines; k++) {
                    if (!placed) {
                        if (point.distanceTo(testPoint) < point.distanceTo(point.closest[k])) {
                            point.closest[k] = testPoint;
                            placed = true;
                        }
                    }
                }
            }
        }
    }
};

/**
 * Animation Loop
 */
Animate.prototype.animate = function () {
    var i;
    // Should we recalucate closest?
    if (this.options.updateClosest) {
        this.findClosest();
    }

    // Calculate Opacity
    for (i in this.points) {
        let distance = this.points[i].distanceTo(this.target, true)
        if (distance < 5000) {
            this.points[i].opacity = 0.15;
            this.points[i].lineWidth = this.options.lineWidth;
            this.points[i].circle.radius = 2
            this.points[i].circle.opacity = 0.3
        } else if (distance < 10000) {
            this.points[i].opacity = 0.1;
            this.points[i].lineWidth = this.options.lineWidth;
            this.points[i].circle.radius = 5;
            this.points[i].circle.opacity = 0;

        } else if (distance < 20000) {
            this.points[i].opacity = 0.1;
            this.points[i].circle.radius = this.options.radius;
            this.points[i].circle.opacity = 0.2;
        } else if (distance < 40000) {
            this.points[i].opacity = 0.05;
            this.points[i].lineWidth = 1;
            this.points[i].circle.radius = 10;
            this.points[i].circle.opacity = 0.05;

        } else {
            this.points[i].opacity = 0;
            this.points[i].lineWidth = this.options.lineWidth;
            // this.points[i].circle.radius = Math.ceil(this.options.radius/5);
            this.points[i].circle.opacity = 0
            // this.points[i].circle.radius = distance/100000;
            // this.points[i].circle.opacity = 1 / this.points[i].circle.radius
        }
    }


    // Clear
    this.ctx.clearRect(0, 0, this.width, this.height);
    for (i in this.points) {

        this.points[i].drawLines();
        this.points[i].circle.draw();
    }
    // Loop
    this.requestedFrame = window.requestAnimationFrame(this.animate.bind(this));
};

/**
 * Starts each point in tween loop
 */
Animate.prototype.shiftPoints = function () {
    for (var i in this.points) {
        this.points[i].shift();
    }
};


/**
 * Make sure the canvas is the right size
 */
Animate.prototype.resize = function () {
    // console.info('resize');
    dimensions.startResize(this.canvas)
    if (this.requestedFrame) {
        window.cancelAnimationFrame(this.requestedFrame)
    }
    this.resizeDebounced()
};
Animate.prototype.resizeDebounced = debounce(function () {
    // console.info('resizeDebounced');
    this.width = dimensions.getWidth()
    this.height = dimensions.getHeight()
    dimensions.setCanvasWithContext(this.canvas, this.ctx)
    this.initGeometry()
    this.animate();
}, 1000);

/**
 * Mouse Move Event - Moves the target with the mouse
 * @param    event   {Object}   Mouse event
 */
Animate.prototype.mousemove = function (event) {
    if (event.clientX || event.clientY) {
        this.target.position.x = event.clientX;
        this.target.position.y = event.clientY;

        // this.target.position.x = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
        // this.target.position.y = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
        // console.info('mouse',this.target.position.x,this.target.position.y);

    } else if (event.pageX || event.pageY) {
        this.target.position.x = event.pageX;
        this.target.position.y = event.pageY;
        // console.info('mouse',this.target.position.x,this.target.position.y);
    }
};

function lerp(v0, v1, t) {
    return v0 * (1 - t) + v1 * t
}

function randomizeOmitCenter(min, max) {
    let rand = Math.random() < 0.5
        ? lerp(0.1, 0.3, Math.random())
        : lerp(0.7, 0.9, Math.random())
    return lerp(min, max, rand)
}

/**
 * Randomly move the target
 */
Animate.prototype.moveTarget = function () {
    var _this = this;

    TweenLite.to(this.target.position, 4, {
        x: randomizeOmitCenter(0, this.width),
        y: randomizeOmitCenter(0, this.height),
        ease: Expo.easeInOut,
        onComplete: function () {
            _this.moveTarget();
        }
    });
};

/**
 * Point Constructor
 * @param    position   {Object}     set of x and u coords
 * @param    ctx        {Object}     Canvas context to draw on
 * @param    options    {Object}     options passed from main function
 */
function Point(position, ctx, id, options) {
    this.options = options || {};
    this.id = id;
    this.ctx = ctx;
    this.position = position || {x: 0, y: 0};
    this.origin = {
        x: this.position.x,
        y: this.position.y
    };
    this.opacity = 0;
    this.closest = [];
}

/**
 * Caluclates the distance to another point
 * @param    point    {Object}    Another Point
 * @param    abs      {Boolean}   Return the absolute value or not
 * @returns  {Number}
 */
Point.prototype.distanceTo = function (point, abs) {
    abs = abs || false;
    var distance = Math.pow(this.position.x - point.position.x, 2) + Math.pow(this.position.y - point.position.y, 2);
    return abs ? Math.abs(distance) : distance;
};

/**
 *  Draws lines to the closet points
 */
Point.prototype.drawLines = function () {
    for (var i in this.closest) {
        if (this.opacity > 0) {
            this.ctx.beginPath();
            this.ctx.moveTo(this.position.x, this.position.y);
            var test = i + 1;
            if (!this.closest[test]) {
                test = 0;
            }
            this.ctx.lineCap = 'round';
            this.ctx.strokeStyle = 'rgba(' + this.options.lineColor + ', ' + this.opacity + ')';
            this.ctx.lineWidth = this.lineWidth || this.options.lineWidth;


            this.ctx.lineTo(this.closest[i].position.x, this.closest[i].position.y);

            this.ctx.stroke();
        }
    }
};

/**
 * Tween loop to move each point around it's origin
 */
Point.prototype.shift = function () {
    var _this = this,
        speed = this.options.speed;

    // Random the time a little
    if (this.options.sync !== true) {
        speed -= this.options.speed * Math.random();
    }
    TweenLite.to(this.position, speed, {
        x: (this.origin.x - (this.options.distance / 2) + Math.random() * this.options.distance),
        y: (this.origin.y - (this.options.distance / 2) + Math.random() * this.options.distance),
        ease: Expo.easeInOut,
        onComplete: function () {
            _this.shift();
        }
    });
};

/**
 * Circle Constructor
 * @param    point   {Object}    Point object
 * @param    ctx     {Object}    Context to draw on
 * @param    options {Object}    options passed from main function
 */
function Circle(point, ctx, options) {
    this.options = options || {};
    this.point = point || null;
    this.radius = this.options.radius || null;
    this.color = this.options.color || null;
    this.opacity = 0;
    this.ctx = ctx;
}


/**
 * Draws Circle to context
 */
Circle.prototype.draw = function () {
    if (this.opacity > 0) {
        // this.ctx.beginPath();
        // this.ctx.fillStyle = 'rgba(' + this.options.circleColor + ', ' + this.opacity + ')';
        // this.ctx.arc(this.point.position.x, this.point.position.y, this.options.radius, 0, 2 * Math.PI, false);
        // this.ctx.fill();

        // this.ctx.fillRect(this.point.position.x-this.radius, this.point.position.y-this.radius,this.radius*2,this.radius*2)

        this.ctx.strokeStyle = 'rgba(' + this.options.circleColor + ', ' + this.opacity + ')';
        this.ctx.strokeRect(this.point.position.x - this.radius, this.point.position.y - this.radius, this.radius * 2, this.radius * 2)


    }
};


/**
 * Utility Function to set default options
 * @param    object    {object}
 * @param    src  {object}
 */
function defaults(object, src) {
    for (var i in src) {
        if (typeof object[i] === 'undefined') {
            object[i] = src[i];
        }
    }
    return object;
}

export default {
    init(options) {
        // Get the balls rolling
        let c = document.getElementById('body-effect')
        let config = {
            mouse: !options.isMobile
        }
        new Animate(c, config);
    }
}
