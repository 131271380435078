<template>
    <div id="order-form" class="card shadow-lg">

        <div class="card-header">
            <h1 class="card-title">{{ trans('order.create-order-title') }}</h1>
        </div>
        <div class="card-body">

            <vue-recaptcha
                ref="recaptcha"
                @verify="onCaptchaVerified"
                @expired="onCaptchaExpired"
                size="invisible"
                :sitekey="recaptchaSite"
            >
            </vue-recaptcha>

            <div v-if="debug" class="card-body">
                <div>OrderType {{ isCrypto ? 'buy' : 'sell' }}</div>
                <div>FromCurrency {{ finalModel.fromCurrency.symbol }}</div>
                <div>ToCurrency {{ finalModel.toCurrency.symbol }}</div>
                <div>isCrypto {{ isCrypto }}</div>
                <div>isLoading {{ isLoading }}</div>
                <div>Email {{ finalModel.email }}</div>
                <div>Phone {{ finalModel.phone }}</div>
                <div>Payment {{ finalModel.tx_vs }}, {{ finalModel.tx_ks }}, {{ finalModel.tx_message }}</div>
                <div>ReferralCode {{ finalModel.referralCode }}</div>
                <button @click="stepSize='xs'">XS</button>
                <button @click="stepSize='md'">MD</button>
                <button @click="stepSize='lg'">LG</button>
            </div>


            <form-wizard @on-complete="onComplete" @on-error="onError" @on-change="onStepChanged"
                         :title="trans('order.create-order-title')" subtitle=""
                         color="color"
                         errorColor="#dc3545"
                         :stepSize="stepSize"
                         shape="tab"
                         ref="wizard"
                         :startIndex="startStep"
                         v-if="!completed"
            >
                <wizard-step
                    slot-scope="props"
                    slot="step"
                    :tab="props.tab"
                    :transition="props.transition"
                    :index="props.index"
                    @click.native="$refs['wizard'].navigateToTab(props.index)"
                >
                    <div slot-no="active-step"
                         style="display: flex; justify-content:center; align-items: center;">
                        <i class="fas fa-calculator" v-if="props.index==0"></i>
                        <i class="fas fa-envelope" v-else-if="props.index == tabIndexMail"></i>
                        <i class="fas fa-wallet" v-else-if="props.index== tabIndexWallet"></i>
                        <i class="fas fa-clipboard-check" v-else></i>
                    </div>
                </wizard-step>


                <tab-content :title="trans('common.amount')" :before-change="() => validateStep('calculator')"
                             icon="fas fa-calculator">
                    <order-calculator
                        :currencies="currencies"
                        :defaultFromCurrency="finalModel.fromCurrency.id"
                        :defaultToCurrency="finalModel.toCurrency.id"
                        :orderPriceUrl="orderPriceUrl"
                        ref="calculator"
                    />
                </tab-content>
                <tab-content :title="trans('common.customer')" :before-change="() => validateStep('customer')" v-if="!loggedEmail"
                             icon="fas fa-user">
                    <order-customer
                        ref="customer"
                        :default-email="finalModel.email"
                        :default-phone="finalModel.phone"
                        :default-referral-code="finalModel.referralCode"
                        :countries="countries"
                        :countriesRiskCodes="countriesRiskCodes"
                    />
                </tab-content>
                <tab-content :title="isCrypto ? trans('common.wallet') : trans('common.account')"
                             :before-change="() => validateStep('payment')" icon="fas fa-wallet">
                    <order-payment
                        ref="payment"
                        :fromCurrency="finalModel.fromCurrency"
                        :toCurrency="finalModel.toCurrency"
                        :from="finalModel.from"
                        :to="finalModel.to"
                        :isCrypto="isCrypto"
                        :email="finalModel.email"
                    />
                </tab-content>
                <tab-content :title="trans('common.summary-step')" :before-change="() => validateStep('summary')"
                             icon="fas fa-clipboard-check">
                    <order-summary
                        ref="summary"
                        slot-scope="props"
                        :active="props.active"
                        :finalModel="finalModel"
                        :isCrypto="isCrypto"
                    />
                </tab-content>

                <template slot="footer" slot-scope="props">
                    <button type="button" class="btn btn-secondary d-none d-sm-inline-block" key="back"
                            @click="props.prevTab()"
                            v-if="props.activeTabIndex > 0 ">
                        <i class="fas fa-angle-left"></i> {{ trans('common.back') }}
                    </button>
                    <button-with-spinner type="button" btn-class="btn btn-primary pull-right" key="continue"
                                         @click="props.nextTab()"
                                         v-if="!props.isLastStep"
                                         :label="trans('common.continue')"
                                         active-icon-class="fas fa-angle-right"
                                         :left-side="false"
                                         :loading="isLoading"/>
                    <button-with-spinner type="button" btn-class="btn btn-primary pull-right" key="complete"
                                         @click="props.nextTab()"
                                         v-if="props.isLastStep"
                                         :label="trans('order.create-order-submit')"
                                         active-icon-class="fas fa-handshake"
                                         :left-side="false"
                                         :loading="isLoading"/>
                </template>

                <zoom-y-transition :duration="500">
                    <div class="alert alert-danger" role="alert" v-if="errorMessage != false">
                        <i class="far fa-frown"></i> {{ errorMessage }}
                    </div>
                </zoom-y-transition>

            </form-wizard>

            <div v-if="completed" id="completed">
                <div class="row p-3">
                    <div class="col-md-4 col-lg-3">
                        <lottie v-if="completed"
                                :options="animOptions"
                                :height="200"
                        />
                    </div>
                    <div class="col-md-8 col-lg-9">
                        <h3 class="pb-3">{{ trans('order.create-order-completed-title') }}</h3>
                        <p v-html="trans('order.create-order-completed-info')"></p>
                        <p>{{ trans('common.your-order-id') }}: <span class="order-id">{{ orderId }}</span></p>
                        <p>{{ trans('common.you-can-close-this-page') }}</p>
                    </div>

                </div>
            </div>

        </div>
    </div>
</template>

<script>
// import Vue from 'vue'
import debounce from 'debounce'
import parser from '../app/parser'
import translator from '../app/translator'
import TabContent from './../vendor/vue-form-wizard/components/TabContent.vue'
import WizardStep from './../vendor/vue-form-wizard/components/WizardStep.vue'
import FormWizard from './../vendor/vue-form-wizard/components/FormWizard.vue'
import Lottie from 'vue-lottie'
import animationSuccess from "../vendor/animation-email";

import {ZoomYTransition} from 'vue2-transitions'
import VueRecaptcha from 'vue-recaptcha';

import OrderCalculator from "./OrderCalculator.vue";
import OrderCustomer from "./OrderCustomer.vue";
import OrderPayment from "./OrderPayment.vue";
import OrderSummary from "./OrderSummary.vue";
import ButtonWithSpinner from "./ButtonWithSpinner.vue";

const PRIORITY_AMOUNT = 1;
const PRIORITY_PRICE = 2;
const TYPE_BUY = 1;
const TYPE_SELL = 2;

export default {
    name: 'order-form',
    props: ['currencies', 'defaultFromCurrency', 'defaultToCurrency', 'defaultFrom', 'orderPriceUrl', 'storeOrderUrl',
        'recaptchaSite', 'recaptchaUrl', 'loggedEmail', 'loggedPhone', 'countries', 'countriesRiskCodes'],
    data: () => {
        return {
            stepSize: 'sm',
            activeIndex: 0,
            // color: '#007bff',
            color: '#5E57EC',
            // color: '#777',
            errorMessage: false,
            loading: false,
            translator: translator,
            loadingCaptcha: false,
            isMounted: false,
            debug: false,
            completed: false,
            orderId: false,
            animOptions: {animationData: animationSuccess, loop: false},
            finalModel: {
                from: null,
                fromCurrency: null,
                to: null,
                toCurrency: null,
                priority: PRIORITY_AMOUNT,
                email: null,
                phone: null,
                wallet: null,
                tx_vs: null,
                tx_ks: null,
                tx_message: null,
                referralCode: null
            },
        }
    },
    components: {
        OrderCalculator, OrderCustomer, OrderPayment, OrderSummary,
        FormWizard, TabContent, WizardStep,
        VueRecaptcha, ZoomYTransition, ButtonWithSpinner, Lottie
    },
    provide() {
        return {trans: this.trans}
    },
    computed: {
        isCrypto() {
            if (this.finalModel.toCurrency) {
                return this.finalModel.toCurrency.isCrypto
            }
            return false
        },
        isLoading() {
            if (!this.isMounted) {
                return true;
            }
            return (this.$refs.wizard && this.$refs.wizard.loading)
                || (this.$refs.calculator && this.$refs.calculator.loading)
                || this.loadingCaptcha
        },
        startStep() {
            return sessionStorage.getItem('orderFromCurrency') === null ? 0 : 1
        },
        tabIndexCalculator() {
            return 0;
        },
        tabIndexMail() {
            return this.loggedEmail ? this.tabIndexCalculator : this.tabIndexCalculator + 1
        },
        tabIndexWallet() {
            return this.tabIndexMail + 1
        },
        tabIndexSummary() {
            return this.tabIndexWallet + 1
        }
    },
    created() {
        // console.info('crateOrder created ');
        let self = this;
        this.finalModel.from = sessionStorage.getItem('orderFrom') || this.defaultFrom
        this.finalModel.to = sessionStorage.getItem('orderTo')
        this.finalModel.fromCurrency = this.findCurrency(sessionStorage.getItem('orderFromCurrency') || this.defaultFromCurrency)
        this.finalModel.toCurrency = this.findCurrency(sessionStorage.getItem('orderToCurrency') || this.defaultToCurrency)
        this.finalModel.priority = sessionStorage.getItem('orderPriority') || PRIORITY_AMOUNT
        this.finalModel.email = this.loggedEmail
        this.finalModel.phone = this.loggedPhone
        this.finalModel.referralCode = sessionStorage.getItem('referralCode') || null
    },
    mounted() {
        let self = this
        self.$root.$on('update:orderType', function (orderType) {
        })
        self.$root.$on('update:fromCurrency', function (currency) {
            self.finalModel.fromCurrency = currency
        })
        self.$root.$on('update:toCurrency', function (currency) {
            self.finalModel.toCurrency = currency
        })
        self.$root.$on('update:from', function (value) {
            self.finalModel.from = value
        })
        self.$root.$on('update:to', function (value) {
            self.finalModel.to = value
        })
        self.$root.$on('update:email', function (value) {
            self.finalModel.email = value
        })
        self.$root.$on('update:phone', function (value) {
            self.finalModel.phone = value
        })
        self.$root.$on('update:referralCode', function (value) {
            self.finalModel.referralCode = value
        })
        self.$root.$on('update:wallet', function (value) {
            self.finalModel.wallet = value
        })
        self.$root.$on('update:priority', function (value) {
            self.finalModel.priority = value
        })
        this.isMounted = true;
    },
    methods: {
        validateStep(step) {
            if (!this.isMounted) {
                return false
            }
            const self = this
            // console.info('validateStep',step);

            app.analytics.trackGAEvent('order-creator', 'step-validation', step)
            return self.$refs[step].validate()
        },
        onComplete: debounce(function () {
            if (this.loadingCaptcha) {
                return;
            }
            // console.info('OrderForm OnComplete');
            this.loadingCaptcha = true
            // console.info('submit');
            this.$refs.recaptcha.execute();
            if (this.$refs.recaptcha.$el) {
                this.$refs.recaptcha.$el.scrollIntoView(false)
            }
        }, 1000, true),
        onCaptchaVerified: function (recaptchaToken) {
            const self = this;
            self.$refs.recaptcha.reset();
            self.loadingCaptcha = false;
            self.storeRequest(recaptchaToken)
        },
        onCaptchaExpired: function () {
            this.loadingCaptcha = false
            this.$refs.recaptcha.reset();
            this.$refs.recaptcha.execute();
        },
        storeRequest(recaptchaToken) {
            let self = this;
            self.loadingCaptcha = true //shared loader
            let data = {
                'g-recaptcha-response': recaptchaToken,
                'tx_vs': self.customSymbols ? self.finalModel.tx_vs : null,
                'tx_ks': self.customSymbols ? self.finalModel.tx_ks : null,
                'tx_message': self.finalModel.tx_message,
                'wallet': self.finalModel.wallet,
                'email': self.finalModel.email,
                'phone': self.finalModel.phone,
                'price_priority': self.finalModel.priority,
                'type': self.isCrypto ? TYPE_BUY : TYPE_SELL,
                'referral_code': self.finalModel.referralCode,

            }
            if (self.isCrypto) {
                data.amount = parser.parseFloat(self.finalModel.to)
                data.price = parser.parseFloat(self.finalModel.from)
                data.market_currency_id = self.finalModel.toCurrency.id
                data.base_currency_id = self.finalModel.fromCurrency.id
            } else {
                data.amount = parser.parseFloat(self.finalModel.from)
                data.price = parser.parseFloat(self.finalModel.to)
                data.market_currency_id = self.finalModel.fromCurrency.id
                data.base_currency_id = self.finalModel.toCurrency.id
            }

            laravel.ajax.send({
                method: 'POST',
                url: this.storeOrderUrl,
                // url: self.recaptchaUrl,
                data: data,
                success: function (p) {
                    self.completed = true
                    self.orderId = p.id;
                    self.loadingCaptcha = false;
                    self.$refs.calculator.clearStorage()
                    app.analytics.trackGAEvent('order-creator', 'submit', 'orderId ' + self.orderId)
                    self.$nextTick(() => {
                        let completeContainer = document.getElementById('completed');
                        if (completeContainer) {
                            completeContainer.scrollIntoView(true)
                        }
                    });

                },
                error: function (e) {
                    self.errorMessage = laravel.errors.singleErrorMessage(e)
                    app.analytics.trackGAEvent('order-creator', 'error', self.errorMessage)
                    self.loadingCaptcha = false
                }
            })
        },
        onError(error) {
            if (error != null) {
                console.info('error', error);
                this.errorMessage = error
            } else {
                this.errorMessage = false;
            }
        },
        onStepChanged(prev, next) {
            this.$root.$emit('wizard-step-summary', next == this.tabIndexSummary)
        },
        trans(term) {
            return this.translator.trans(term)
        },
        findCurrency: function (id) {
            return this.currencies.filter(currency => currency.id == id)[0];
        }
    }

}
</script>

<style lang="scss">
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$gocoin: #5E57EC;
$gocoin2: #9747DC;
$gray: #ebebeb;
$red: #dc3545 !default;
$blue: #007bff !default;
$dindigo: #36266d !default;
$card-cap-bg: #e8e8e8;

#order-form {

    .card-header {
        border-bottom: 0;
        background-color: $card-cap-bg;
    }

    .card-title {
        text-align: center;
        @media (max-width: 400px) {
            font-size: 1.3rem;
        }
    }

    border-radius: 17px;

    .card-body {
        padding: 0;
        @media (max-width: 400px) {
            /*padding: 1.25rem 0;*/

        }
    }

    #order-calculator, .order-form-content {
        /*background-color: $gray; */
        /*background-color: #FFF;*/
        /*border: 0;*/
        /*border-radius: 10px;*/
        /*border-top: 1px $gray-300 solid; */
        border-radius: 0;
        color: #000;
        padding: 1rem;
        margin-top: 1rem;

        code {
            /* color:$blue; */
            font-weight: bold;
        }

        @media (max-width: 400px) {
            margin: 8px;

        }
    }


    .order-id {
        font-family: monospace, serif;
        font-size: 2rem;
        display: inline-block;
        border: 1px solid $gocoin2;
        border-radius: 15px;
        padding: 0.25rem 0.5rem;

    }

    .grecaptcha-badge {
        display: none;
    }

    #completed {
        .row {
            margin-left: 10px;
            margin-right: 10px;
        }
    }


}
</style>
