import cs from './../../../../lang/cs/exported'
import en from './../../../../lang/en/exported'

var exported = {
    cs, en
}

export default {

    vocabulary: {},
    locale: null,
    init: function (locale = 'cs') {
        this.locale = locale
        this.addExported()
    },
    addExported() {
        this.vocabulary = $.extend(this.vocabulary, exported[this.locale]);
    },
    add: function (vocabulary, domain = 'default') {
        this.vocabulary[domain] = vocabulary
    },
    trans: function (term, domainKey = 'default') {
        let parts = term.split('.');
        if (parts.length == 2) {
            domainKey = parts[0]
            term = parts[1]
        }
        let domain = this.getDomain(domainKey)
        if (domain) {
            if (domain.hasOwnProperty(term)) {
                return domain[term]
            } else {
                console.error(`Translation - term missing  '${domainKey}.${term}'`);
            }
        }
        return term;
    },
    getDomain: function (domain) {
        if (this.vocabulary.hasOwnProperty(domain)) {
            return this.vocabulary[domain]
        } else {
            console.error(`Translation - domain '${domain}' missing`);
        }
    },
    debug: function () {
        console.info('trans vocabulary', this.vocabulary);
    }

}
